/**
 * 路由合并汇总
 * 增加的路由文件需引入，并写入routes
 * 返回首页按钮的top值需写入路由的meta中
 * */
import login from './login'// 首页+登陆相关
import column from './column'// 线上专栏相关
import live from './live'// 直播相关
import classify from './classify'// 分类相关
import message from './message'// 消息相关
import task from './task'// 任务相关
import center from './center'// 我的 相关
import oauth from '../oauth/oauth'//登录空白页
import packAge from './package'//套餐相关
import error from './error'//数据错误时跳转页面
import svip from './svip'// vip相关
import relatives from './relatives'// vip相关
import account from './account'// 账户相关
import deposit from './deposit'// 活动押金
import agent from './agent'// 账户相关
import activity from './activity'// 活动相关
import Joint from './Joint'// 机构打通相关
import search from './search'// 搜索相关
import agreement from './agreement'// 协议
import customerService from './customerService'// 智能客服相关
import shopping from './shopping'// 商城相关
import payment from './payment'// 收款相关
import news from './news'// 头条相关
import extensionPoster from './extensionPoster'// 合伙人推广相关
import wallet from './wallet'// 钱包相关
import meet from './meet'// 约见相关
import postersPromoting from './postersPromoting'// 海报推广相关
import coupon from './coupon'// 优惠劵相关
import learningRecord from './learningRecord'// 学习记录相关
import bonus from './bonus'// 打卡抽奖
import specialColumn from './specialColumn'// 专栏
import buySuccess from './buySuccess'//购买成功
import data from './data'// 资料相关
import words from './words'// 每日心语
import group from './group'// 机构本身相关，例如关于我们
import freeVideo from './freeVideo'// 免费视频
import tribe from './tribe'// 部落
import form from './form'// 表单
import home from './home'// 表单
import demo from './demo'// demo示例
import train from './train'// 训练营相关
import depositClass from './depositClass'// 订金课
import serviceProvider from './serviceProvider'// 服务商相关
import withdraw from './withdraw'// 提现相关
import pkList from './pkList'// 新pk榜
import profile from './profile'// 资料相关
import share from './share'// 分享素材
import buySucceeds from './buySucceeds'// 购买成功跳转
import gift from './gift'// 赠好友
import expert from './expert'// 专家
import chart from './chart'// 图表
import mine from './mine'// 赠好友
import wecom from './wecom'// 企微
import club from './club'// 俱乐部
import divination from './divination' //时间盘-卦象
import sinology from './sinology'//时间盘
import partTime from './partTime'// 供应商
import spaceDiskV1 from './spaceDiskV1'// 空间盘-导入户型图
const routes = [
    ...login,
    ...column,
    ...live,
    ...classify,
    ...message,
    ...task,
    ...center,
    ...oauth,
    ...packAge,
    ...error,
    ...svip,
    ...relatives,
    ...account,
    ...deposit,
    ...agent,
    ...activity,
    ...Joint,
    ...search,
    ...agreement,
    ...customerService,
    ...shopping,
    ...payment,
    ...news,
    ...extensionPoster,
    ...wallet,
    ...meet,
    ...postersPromoting,
    ...coupon,
    ...learningRecord,
    ...bonus,
    ...specialColumn,
    ...buySuccess,
    ...data,
    ...words,
    ...group,
    ...freeVideo,
    ...form,
    ...train,
    ...tribe,
    ...home,
    ...demo,
    ...depositClass,
    ...serviceProvider,
    ...withdraw,
    ...pkList,
    ...profile,
    ...share,
    ...buySucceeds,
    ...gift,
    ...expert,
    ...chart,
    ...mine,
    ...wecom,
    ...club,
    ...divination,
    ...sinology,
    ...partTime,
    ...spaceDiskV1,
]
// let routeList = [
//     login,
//     column,
//     live,
//     classify,
//     message,
//     task,
//     center,
//     oauth
// ];
// let routes = [];
// for (let routeModuleIndex in routeList) {
//     for (let routeIndex in routeList[routeModuleIndex]) {
//         routes.push(routeList[routeModuleIndex][routeIndex]);
//     }
// }
export default routes