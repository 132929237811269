/*专栏相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    
    allCategoriesApi: () => {//机构所有的分类
        return axios.get('/products/teachers-category');
    },
    classificationBannerApi: (id, data) => {//分类的banner图片
        return axios.get('/api/api/mechanism/category/ads/' + id, data);
    },
    classificationMaterialApi: (id, obj) => {//专栏-分享素材
        return axios.get('/api/api/column/share-data/' + id, obj);
    },
    // teacherApi: (id) => {//分类下所有的老师
    //     return axios.get('/api/api/mechanism/category/teacher/' + id);
    // },
    teacherApi: (data) => {//专栏列表
        return axios.get(`/products/teachers`, data);
    },
    // columnDetailsApi: (id) => {//分类的详细信息
    //     return axios.get('/api/api/mechanism/category/show/' + id);
    // },
    columnDetailsApi: (id) => {//分类的详细信息
        return axios.get(`/products/teachers/category-package/${id}`);
    },
    classificationShareApi: (type, obj) => {//专栏-分享接口
        return axios.get('api/api/mechanism/share_info/' + type, obj);
    },
    columnIntroduction: (teacherId) => {//新专栏-获取简介
        return axios.get(`/products/teacher/${teacherId}/info`);
    },
    columnGift: (teacherId) => {//新专栏-获取是否赠好友
        return axios.get(`/products/teacher/buy_give_status/${teacherId}`);
    },
    // vip 栏目大礼包
    vipData: (group_id, obj) => {//vip-获取信息
        return axios.requestGet('/v1/mechanisms/price/' + group_id, obj);
    },
    courseData: (data) => {//vip-获取课程
        return axios.get(`/products/teachers`, data);
    },
    posterInfo: (group_id, obj) => {//vip-是否有海报接口
        return axios.requestGet('/v1/posters/partner/' + group_id + '/16', obj);
    },
    shareInfo: (obj) => {//vip-分享接口
        return axios.get('/api/api/custom-share', obj);
    },
    // 视频详情页的接口
   
    getVideoInfo: (videoId) => {//videoDetail-视频详情信息
        return axios.get('/products/teacher/video/' + videoId);
    },
    
    getVideoUrl: (videoId) => {//videoDetail-视频和音频地址
        return axios.get('/products/teacher/video/' + videoId + '/auth');
    },
    
    putVideoProgress: (videoId, obj) => {//videoDetail-视频播放秒数
        return axios.put('products/teacher/videos/playback-progress/' + videoId, obj);
    },
    
    getVideoList: (videoId) => {//videoDetail-视频列表
        return axios.get('/products/teacher/video/' + videoId + '/list');
    },
    getVideoAds: () => {//videoDetail-广告位
        return axios.get('/api/api/column/lanmu/advert');
    },
    videoCollect: (obj) => {//videoDetail-收藏
        return axios.requestPost('/v2/ucentor/collects', obj);
    },
    videoDelCollect: (videoId) => {//videoDetail-取消收藏
        return axios.iDelete('/api/api/videos/' + videoId + '/collect-cancel');
    },
   
    postVideoHistory: (obj) => {//videoDetail-观看历史
        return axios.requestPost('/v1/ucentor/histories', obj);
    },
    //视频的回复评论
    

    getGiftInfo: (giftId) => {//giftDetail- 栏目买赠-获取详情信息
        return axios.get('/api/api/member_give_product/' + giftId);
    },
    transferringInfo: (data) => {//giftDetail- 转增转卖详情
        return axios.get('/products/give_product/order/info',data);
    },
    transferringList: (data) => {//giftDetail- 转增转卖领取记录列表
        return axios.get('/products/give_product/receive/list', data);
    },
    getGiftRecord: (data) => {//giftDetail- 栏目买赠-获取赠送记录
        return axios.get('/api/api/member_receive_product', data);
    },
    getGiftStatus: (data) => {//giftReceive- 栏目买赠-获取赠送状态
        return axios.get('/api/api/member_give_product', data);
    },
    getGiftColumnInfo: (columnId) => {//giftReceive- 栏目买赠-获取赠送的栏目信息
        return axios.get('/api/api/lanmu_info/' + columnId);
    },
    postGiftReceive: (data) => {//giftReceive- 栏目买赠-领取赠送的栏目
        return axios.post('/api/api/member_give_product', data);
    },
    getGiftColumnInfo2: (columnId, data) => {//giftShare- 栏目买赠-分享赠送的栏目
        return axios.get('/api/api/lanmu_info/' + columnId, data);
    },
    vipCoupons: (productId) => axios.get(`/products/give-coupon/${productId}`),//vip获取赠送优惠卷
    vipTheirPrice: (data) => axios.get(`/products/prices`, data),//vip获取价格
    vipDetails: () => axios.get(`/products/vip`),//vip详情
    vipCost: () => axios.get(`/products/vip-cost`),//获取vip成本续费
    vipShareInfo: () => axios.get(`/products/custom-share/2/0`),//vip分享
    vipCouponsList: (data) => axios.get(`/api/api/coupon/product/usable`, data),//vip优惠券列表
    getEvaluateTags: () => {//获取标签
        // return axios.requestGet('/v1/ucentor/comment-teachers/tags');
        return axios.get('/products/teacher-score-tags');
    },
    getScoringdetails: (commentId) => {//获取评分详情
        return axios.get('/products/teacher/score-show/' + commentId);
    },
    getEvaluatePost: (productId, data) => {//提交评论
        return axios.post('/products/teacher/score/' + productId, data);
    },
    getEvaluatePut: (productId, data) => {//修改评论
        return axios.put('/products/teacher/score/' + productId, data);
    },
    getScoreInfo: (videoId) => {//视频评分信息
        return axios.get('/products/video/score/' + videoId);
    },
    getScoreInfoSub: (productId, data) => {//视频评分
        return axios.post('/products/video/score/' + productId, data);
    },
    publicCustomShare: (id, columnId) => {//giftShare- 栏目买赠-分享赠送的栏目
        return axios.get(`/products/custom-share/${id}/${columnId}`);
    },
    getVideoComments: (videoId, curPage) => {//videoDetail-评论列表
        return axios.get(`/products/comments/1/${videoId}?page=${curPage}`);
    },
    postVideoComments: (videoId,obj) => {//videoDetail-发布评论和回复评论 //videoComment-回复评
        return axios.post(`/products/comment/video/${videoId}`, obj);
    },
     //视频的回复评论
     videoReplyList: (commentId, curPage) => {//videoComment-评论列表
        return axios.get(`/products/comment/reply/${commentId}?page=${curPage}`);
    },
    commentDetails: (commentId) => {//评论详情
        return axios.get(`/products/${commentId}/comment`);
    },
    // 获取ai笔记下的大纲和总结
    getNoteDetailApi: (videoId, type) => {
        return axios.get(`/products/teacher/ai_note/${videoId}/${type}`);
    }
}