/* 我的 相关 */
/* keepAlive:该页面是否缓存 */
export default [
    {
        path: 'mechanism-center',
        name: 'mechanism-center',
        meta: {
            title: '会员中心',
            isShowTab: true
        },
        component: () => import('@/components/tabbarPage/mechanism-center.vue')
    },
    {
        path: 'center/result',
        name: 'result',
        meta: {
            title: '绑定结果',
            isLoading:true,
            // backHome: false,
            // isShowTab: true,
        },
        component: () =>
            import('@/views/center/result.vue')
    },
    {
        path: 'center/homepage',
        name: 'homepage',
        meta: {
            title: '',
            backHome: false,
            keepAlive:true,
            backSource:'', //用于判断上一个页面是哪个
        },
        component: () =>
            import('@/views/center/homepage.vue')
    },
    {
        path: 'center/editPage',
        name: 'editPage',
        meta: {
            title: '编辑资料',
            backHome: false,
            iconTop: '208px',
        },
        component: () =>
            import('@/views/center/editPage.vue')
    },
    {
        path: 'center/introduction',
        name: 'introduction',
        meta: {
            title: '详细介绍',
            backHome: false,
            isLoading: true
        },
        component: () =>
            import('@/views/center/introduction.vue')
    },
    {
        path: 'center/contacts',
        name: 'contacts',
        meta: {
            title: '',
            backHome: false,
        },
        component: () =>
            import('@/views/center/contacts.vue')
    },
    {
        path: 'center/myCourse/course',
        name: 'course',
        meta: {
            title: '我的课程',
            backHome: false,
        },
        component: () =>
            import('@/views/center/myCourse/course.vue')
    },
    {
        path: 'center/myCourse/rainCheck',
        name: 'rainCheck',
        meta: {
            title: '改期',
            backHome: false,
        },
        component: () =>
            import('@/views/center/myCourse/rainCheck.vue')
    },
    {
        path: 'center/myCourse/svipAll',
        name: 'svipAll',
        meta: {
            backHome: false,
        },
        component: () =>
            import('@/views/center/myCourse/svipAll.vue')
    },
    {
        path: 'center/myCourse/svipProducts',
        name: 'svipProducts',
        meta: {
            title: "专属优惠产品",
            backHome: false,
        },
        component: () =>
            import('@/views/center/myCourse/svipProducts.vue')
    },
    // 学习区-我的直播
    {
        path: 'center/myLive',
        name: 'myLive',
        meta: {
            title: '我的直播'        
        },
        component: () => import('@/views/center/myLive.vue')
    },
    {
        path: 'center/incomClassifiy',
        name: 'incomClassify',
        meta: {
            title: '账户'        
        },
        component: () => import('@/views/center/incomClassify.vue')
    },
    // 合伙人区
    {// PK榜-列表
        path: 'center/rankingList',
        name: 'rankingList',
        meta: {
            title: 'PK榜',
            backHome: false,
        },
        component: () => import('@/views/center/ranking/rankingList.vue')
    },
    {// PK榜-详情
        path: 'center/rankingDetails',
        name: 'rankingDetails',
        meta: {
            title: 'PK榜',
            backHome: false,
        },
        component: () => import('@/views/center/ranking/rankingDetails.vue')
    },
    {// PK榜-奖品页面
        path: 'center/rankingPrize',
        name: 'rankingPrize',
        meta: {
            title: 'PK榜',
            backHome: false,
        },
        component: () => import('@/views/center/ranking/rankingPrize.vue')
    },
    {// 引导加群-列表
        path: 'center/wechatGroup/groupAddList',
        name: 'groupAddList',
        meta: {
            title: '引导加群',
            backHome: false,
        },
        component: () => import('@/views/center/wechatGroup/groupAddList.vue')
    },
    {// 引导加群-邀请页面
        path: 'center/wechatGroup/groupInvitation',
        name: 'groupInvitation',
        meta: {
            title: '微信群海报',
            backHome: false,
        },
        component: () => import('@/views/center/wechatGroup/groupInvitation.vue')
    },
    {// 引导加群-群邀请规则群
        path: 'center/wechatGroup/groupRule',
        name: 'groupRule',
        meta: {
            title: '微信群海报',
            backHome: false,
        },
        component: () => import('@/views/center/wechatGroup/groupRule.vue')
    },
    {// 引导加群-海报二维码
        path: 'center/wechatGroup/groupShare',
        name: 'groupShare',
        meta: {
            title: '微信群海报',
            backHome: false,
        },
        component: () => import('@/views/center/wechatGroup/groupShare.vue')
    },
    {// 引导加群-扫码进群
        path: 'center/wechatGroup/groupImg',
        name: 'groupImg',
        meta: {
            title: '群二维码名片',
            backHome: false,
        },
        component: () => import('@/views/center/wechatGroup/groupImg.vue')
    },
    {//我发布的直播列表
        path: 'center/live/shareList',
        name: 'liveShareList',
        meta: {
            title: '发布',
            backHome: false,
        },
        component: () => import('@/views/center/live/shareList.vue')
    },
    {//邀请达人榜
        path: 'center/live/invitationlist',
        name: 'liveInvitationList',
        meta: {
            title: '邀请达人榜',
            backHome: false,
        },
        component: () => import('@/views/center/live/invitationList.vue')
    },
    {//邀请榜详情
        path: 'center/live/friendList',
        name: 'liveFriendList',
        meta: {
            title: '邀请榜详情',
            backHome: false,
        },
        component: () => import('@/views/center/live/friendList.vue')
    },
    {//我的收藏
        path: 'center/myCollect',
        name: 'myCollect',
        meta: {
            title: '我的收藏',
        },
        component: () => import('@/views/center/myCollect.vue')
    },
    {//我参与的直播
        path: 'center/live/joinList',
        name: 'joinList',
        meta: {
            title: '我参与的直播',
            backHome: false,
        },
        component: () => import('@/views/center/live/joinList.vue')
    },
     {//验票-学员
        path: 'center/myCourse/activityTicket',
        name: 'activityTicket',
        meta: {
            title: '验票',
            backHome: false,
        },
        component: () => import('@/views/center/myCourse/activityTicket.vue')
    },
    {//进行验票-验票员
        path: 'center/myCourse/checkTicket',
        name: 'checkTicket',
        meta: {
            title: '验票',
            backHome: false,
        },
        component: () => import('@/views/center/myCourse/checkTicket.vue')
    },
    
     {//我的分批列表
        path: 'center/partial',
        name: 'centerPartial',
        meta: {
            title: '我的分批',
        },
        component: () => import('@/views/center/partial.vue')
    },
    {//我的分批详情
        path: 'center/partialDetails',
        name: 'partialDetails',
        meta: {
            title: '分批付款',
        },
        component: () => import('@/views/center/partialDetails.vue')
    },
     {//积分详情
        path: 'center/integral/integral',
        name: 'centerIntegral',
        meta: {
            title: '积分',
        },
        component: () => import('@/views/center/integral/integral.vue')
    },
    {//积分排行
        path: 'center/integral/ranking',
        name: 'integralRanking',
        meta: {
            title: '排行榜',
        },
        component: () => import('@/views/center/integral/ranking.vue')
    },
    {//积分排行
        path: 'center/integral/useRules',
        name: 'integralUseRules',
        meta: {
            title: '使用规则',
        },
        component: () => import('@/views/center/integral/useRules.vue')
    },
    {//积分明细
        path: 'center/integral/details',
        name: 'integralDetails',
        meta: {
            title: '积分明细',
        },
        component: () => import('@/views/center/integral/details.vue')
    },
    {//直播订单
        path: 'center/liveOrder/liveOrder',
        name: 'liveOrder',
        meta: {
            title: '直播订单',
        },
        component: () => import('@/views/center/liveOrder/liveOrder.vue')
    },
    {//直播订单搜索
        path: 'center/liveOrder/liveSearch',
        name: 'liveSearch',
        meta: {
            title: '直播订单',
        },
        component: () => import('@/views/center/liveOrder/liveSearch.vue')
    },
    {//注销账号
        path: 'center/logout/cancellation',
        name: 'cancellation',
        meta: {
            title: '注销账号',
        },
        component: () => import('@/views/center/logout/cancellation.vue')
    },
    {//账号注销安全验证
        path: 'center/logout/verification',
        name: 'verification',
        meta: {
            title: '账号注销安全验证',
        },
        component: () => import('@/views/center/logout/verification.vue')
    },
     {//注销须知
        path: 'center/logout/notice',
        name: 'notice',
        meta: {
            title: '注销须知',
        },
        component: () => import('@/views/center/logout/notice.vue')
    },
]