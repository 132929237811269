/*  专栏相关 */
/* keepAlive:该页面是否缓存 */
export default [
    {
        path: 'the-column/subscribe',
        name: 'subscribe',
        meta: {
            title: '专栏',
            keepAlive: false,
        },
        component: () => import('@/views/the-column/subscribe.vue')
    },
    {
        path: 'the-column/columnPack',
        name: 'columnPack',
        meta: {
            title: '',
            keepAlive: false,
        },
        component: () => import('@/views/the-column/columnPack.vue')
    },
    {
        path: 'the-column/pay',
        name: 'pay',
        meta: {
            title: '订单支付',
        },
        component: () => import('@/views/the-column/pay.vue')
    },
    {
        path: 'the-column/material',
        name: 'material',
        meta: {
            title: '分享素材',
            // isShowTab: true
        },
        component: () => import('@/views/the-column/material.vue')
    },
    {
        path: 'the-column/ntroduction',
        name: 'ntroduction',
        meta: {
            title: '专栏简介',
        },
        component: () => import('@/views/the-column/ntroduction.vue')
    },
    // {
    //     path: '/the-column/danMu',
    //     name: 'danMu',
    //     meta: {
    //         title: '专栏简介'
    //     },
    //     component: () => import('@/views/the-column/danMu.vue')
    // },
    {
        path: 'the-column/vip',
        name: 'vip',
        meta: {
            title: 'vip',
            // isLoading: true
        },
        component: () => import('@/views/the-column/vip.vue')
    },
    {//视频详情
        path: 'the-column/videoDetail',
        name: 'videoDetail',
        meta: {
            title: '详情',
        },
        component: () => import('@/views/the-column/videoDetail.vue')
      },
      {//ai笔记
        path: 'the-column/aiNoteDetail',
        name: 'aiNoteDetail',
        meta: {
            title: '详情',
        },
        component: () => import('@/views/the-column/aiNoteDetail.vue')
    },
    {
        path: 'the-column/videoComment',
        name: 'videoComment',
        meta: {
            title: '评论详情',
        },
        component: () => import('@/views/the-column/videoComment.vue')
    },
    {//专栏买赠--赠送页
        path: 'the-column/giftShare',
        name: 'giftShare',
        meta: {
            title: '免费赠送好友',
        },
        component: () => import('@/views/the-column/giftShare.vue')
    },
    {//专栏买赠--详情
        path: 'the-column/giftDetail',
        name: 'giftDetail',
        meta: {
            title: '专栏免费赠送好友',
        },
        component: () => import('@/views/the-column/giftDetail.vue')
    },
    {//专栏买赠--领取
        path: 'the-column/giftReceive',
        name: 'giftReceive',
        meta: {
            title: '专栏详情',
        },
        component: () => import('@/views/the-column/giftReceive.vue')
    },
    {//专栏买赠--领取
        path: 'the-column/evaluate',
        name: 'evaluate',
        meta: {
            title: '专栏评价',
        },
        component: () => import('@/views/the-column/evaluate.vue')
    },
]