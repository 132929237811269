import axios from '../axios'

export default {
    getLiveList: (data) => axios.get('/api/api/live/list', data),// 直播列表
    getGroupList: (data) => axios.get('/api/api/live/group/list', data),// 直播列表分类
    // getLiveData: (liveId, data) => axios.get('/api/api/goods_live/' + liveId, data),// 直播信息 直播页和回看页都请求了
    getLiveData: (liveId, data) => axios.get('/api/api/goods-live/' + liveId, data),// 最新版直播信息 直播页和回看页都请求了  
    getVideoUrl: (liveId, data) => axios.get('/api/api/goods-live/' + liveId + '/auth', data),// 直播信息 直播页和回看页都请求了
    getHaveApp: (mechanism_id) => axios.get('/api/api/app/' + mechanism_id + '/status'),// 直播获取机构是否有app
    postInvite: (data) => axios.post('/api/api/goods_live_info/invitation_list', data),// 直播-添加邀请榜数据
    getInviteList: (data) => axios.get('/api/api/goods_live_info/invitation_list', data),// 直播邀请榜
    getSelfInviteList: (data, mechanism_id) => axios.get('/api/api/goods_live_info/invitation_list/' + mechanism_id, data),// 直播邀请榜-自己邀请的
    getLiveUrl: (liveId) => axios.get('/api/api/pull-flow-address/' + liveId),// 直播拉流链接
    postReward: (data) => axios.requestPost('/v1/ucentor/award-orders', data),// 直播-打赏-下单
    accountPay: (data) => axios.requestPost('/v1/ucentor/pays/pay', data),// 直播-打赏-余额支付
    getBagProduct: (data) => axios.get('/api/api/live/products/List', data),// 直播获取直播推荐产品-小书包  助手推产品
    postFollowAnchor: (data) => axios.post('/api/api/live/anchor/follow', data),// 直播-关注主播
    // getFollowSubscription: (mechanism_id) => axios.get('/api/api/user_attention_wechat_status?mechanism_id=' + mechanism_id),// 直播-获取关注公众号 已改为从vueX获取followInfo
    getCouponList: (liveId) => axios.get('/api/api/goods_live_info/coupon/audience/' + liveId),// 直播-优惠券列表
    getOnlineData: (liveId) => axios.get('/api/api/live/connect/apply/list?live_id=' + liveId),// 直播-连麦列表
    postEndOnline: (data) => axios.post('/api/api/live/connect/current/end', data),// 直播-结束连麦
    getCurrentUser: (data) => axios.get('/api/api/live/current/user', data),// 直播-观众列表-连麦
    getSearchViewer: (data) => axios.get('/api/api/live/current/user/search', data),// 直播-搜索观众
    getGagList: (data) => axios.get('/api/api/live/oper/list', data),//  类型1踢出2禁言 直播-禁言列表 和 踢出列表
    postAdminPeople: (liveId, data) => axios.post('/api/api/goods_live_info/user_processing/' + liveId, data),// 直播-管理用户 禁言 解禁 踢出房间
    getEndData: (liveId) => axios.get('/api/api/goods_live_info/live_ends_data/' + liveId),// 直播-用户被踢出或者直播已结束页面使用的数据
    getUserInfo: (liveId, data) => axios.get('/api/api/goods_live_info/audience_info/' + liveId, data),// 直播-获取用户信息
    getPopupBagList: (data) => axios.get('/api/api/live/products/popup', data),// 直播-获取回看和伪直播推产品
    putVideoProgress: (data) => axios.post('/api/api/live/user/play/time/update', data),



    getRedInfo: (data) => axios.get('/api/api/luck/money/index', data),// 红包-可领取红包详情
    luckJoin: (data) => axios.post('/api/api/luck/money/join', data),// 红包-参与抢本次红包
    luckCheck: (data) => axios.get('/api/api/luck/money/user/check', data),// 红包-是否中奖
    luckUserList: (data) => axios.get('/api/api/luck/money/user/list', data),// 红包-中奖列表
    giveOutPrice: (data) => axios.post('/api/api/luck/money/start', data),// 红包-发红包
    getRedList: (data) => axios.get('/api/api/luck/money/list', data),// 红包-发红包列表

    getLotteryInfo: (data) => axios.get('/api/api/lottery/index', data),// 抽奖-获取抽奖信息
    getLotteryCheck: (data) => axios.get('/api/api/lottery/check/user', data),// 抽奖-查看是否中奖
    getLotteryList: (data) => axios.get('/api/api/lottery/list', data),// 抽奖-奖品列表
    lotteryJoin: (data) => axios.post('/api/api/lottery/join', data),// 抽奖-参与本次抽奖
    lotteryUserList: (data) => axios.get('/api/api/lottery/award/user', data),// 抽奖-中奖人员列表
    livePosters: (liveId) => axios.get('/api/api/goods_live_info/live_ends_data/' + liveId),// 直播海报
    liveTemplateList: (liveId) => axios.get('/api/api/live/poster/' + liveId),// 海报模板列表
    livePersonal: (groupId, infoId) => axios.get(`/api/api/live/anchor/home/${groupId}/${infoId}`),// 直播主页
    focus: (data) => axios.post('/api/api/live/anchor/follow', data),// 关注
    liveFollowList: (groupId, data) => axios.get(`/api/api/live/anchor/list/${groupId}`, data),// 关注主播列表
    liveAudioList: (groupId, infoId, data) => axios.get(`/api/api/live/anchor/column/${groupId}/${infoId}`, data),// 音视频列表
    liveactivityList: (groupId, infoId, data) => axios.get(`/api/api/live/anchor/activity/${groupId}/${infoId}`, data),// 线下活动列表
    liveanchorList: (groupId, infoId, data) => axios.get(`/api/api/live/anchor/live/${groupId}/${infoId}`, data),// 往期视频列表
    liveHideShow: (data) => axios.post(`/api/api/live/anchor/status`, data),// 往期视频列表显示隐藏
    liveMyFollowList: (data) => axios.get(`/api/api/live/anchor/follow/list/`, data),// 关注主播列表
    editor: (data, groupId) => axios.post(`/api/api/live/anchor/update/info/${groupId}`, data),// 个人中心编辑
    hiddenPast: (data) => axios.post(`/api/api/live/anchor/status`, data),// 往期直播隐藏 or 取消隐藏

    liveTopData: (liveId, data) => axios.get(`api/api/goods_live_info/other_data/${liveId}`, data),// 我发布的-直播查看数据-头部数据
    participantData: (liveId, data) => axios.get(`api/api/goods_live_info/participant_data/${liveId}`, data),// 我发布的-直播查看数据-参与列表
    rewardData: (liveId, data) => axios.get(`api/api/goods_live_info/reward_data/${liveId}`, data),// 我发布的-直播查看数据-打赏列表
    goodsInfoList: (data) => axios.get(`api/api/goods-live/goods-info`, data),// 我发布的-直播查看数据-带货列表

    getJoinList: (data) => axios.get('/api/api/my_goods_live/history', data),// 我参与的直播列表
    getJoinDel: (liveId) => axios.iDelete(`/api/api/my_goods_live/history/${liveId}`),// 我参与的直播列表-删除

    reportInfo: (data) => axios.post('/api/api/item/live/report', data),// 直播埋点
    superBoardList: (data) => axios.get('/api/api/live/ppt/list', data),// 直播白板列表
    lookTime: (data) => axios.post('/api/api/live/user/line', data),// 直播用户观看时长
    updateGoodsList: (data) => axios.post('/api/api/live/products/update', data),// 直播用户观看时长
    getAnchorCouponList: (liveId) => axios.get('/api/api/goods_live_info/coupon/anchor/' + liveId),// 直播-助手推的优惠券列表

    queryMember: (data) => axios.socketGet('/room/is-member', data),// 查找用户是否在直播间
    sendNotice: (data) => axios.post('/api/api/live/on-the-wall', data),// 发公告
    liveRealNum: (data) => axios.get('/api/api/live/current/user/num', data),// 直播间真实在线人数
    liveProductsHide: (data) => axios.post('/api/api/live/products/hide/update', data),// 直播间-小书包商品隐藏显示
    liveCouponHide: (data) => axios.post('/api/api/live/coupon/hide/update', data),// 直播间-优惠券领光领取
    liveTime: (data) => axios.get('/api/api/live/time', data),// 直播间-服务器时间

    whitelist: (liveId) => axios.get(`/api/api/live/whitelist?live_id=${liveId}`),// 直播白名单列表
}