/*约见相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    meetList: (data) => axios.get('/api/api/appoint', data),//约见列表
    meetClassification: () => axios.get('api/api/appoint/category'),//约见分类
    meetSearch: () => axios.get('/api/api/appoint/search'),//约见筛选
    profile: (data) => axios.get('/api/api/appoint/user/loggor/profile', data),//约见评价
    appraise: (data) => axios.post('/api/api/appoint/user/appraise', data),//发布约见评价
    userProfile: (data) => axios.get('/api/api/appoint/user/loggor/profile', data),//查看约见评价
    expertProfile: (data) => axios.get('/api/api/appoint/expert/loggor/profile', data),//查看约见评价
    reply: (data) => axios.get('/api/api/appoint/user/appraise', data),//查看约见评价
    expertsReply: (data) => axios.post('/api/api/appoint/expert/answer', data),//专家回复
    appointProfile: (data) => axios.get('/api/api/appoint/profile', data),// 获取约见头部信息
    appointTopic: (data) => axios.get('/api/api/appoint/topic', data),// 获取话题列表
    appointAppraise: (data) => axios.get('/api/api/appoint/appraise', data),// 获取评价列表数据
    appointCollect: (data) => axios.post('/api/api/appoint/user/collect', data),// 取消收藏和收藏
    // protocolsSign: (data) => axios.post('/api/api/protocols/sign', data),// 确认购买，立即预约
    topicProfile: (data) => axios.get('/api/api/appoint/topic/profile', data),// 获取话题信息
    getProtocols: (data) => axios.get('/api/api/protocols', data),// 协议 typeNumber 1是代理 0是其它的产品  proxy_protocol:0只有系统协议 1只有代理协议
    canBuyMeet: (data) => axios.post('/api/api/appoint/can/buy', data),// 判断用户是否可购买约见
    getArea: (id) => axios.get(`/api/api/area/${id}`),//  获取所有省、市、区
    getAreaMeetingApi: (id) => axios.get(`/products/appoint/area/${id}`),//  获取有约见数据的所有省、市、区
    editComment: (appraise_id,data) => axios.put(`/api/api/appoint/user/appraise/${appraise_id}`,data),// 学员编辑评价
    topicInfo: (data) => axios.get(`/api/api/appoint/user/place`,data),// 指定约见话题信息
    getFormInfo: (meetId) => axios.get(`/products/appoint/form_field/${meetId}`),// 约见获取表单
    submitFormInfo: (data) => axios.post(`/products/appoint/submit_form`,data),// 约见提交表单
    addressCheck: (data) => axios.get(`/products/appoint/user_address/check`,data),// (新)地址在不在服务范围内
   
    getMeetPrice:(data)=>axios.get(`/products/appoint/best_price`,data),// 约见获取价格
    getFlow: (data) => axios.get(`/api/api/appoint/flow`, data),// 约见流程
    getMeetingIpApi: (data) => axios.get(`/api/tools/ip_address`, data),// 获取地址ip
    
}